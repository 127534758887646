import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import Helmet from "react-helmet"
import SEO from "../components/seo"

const HostingPage = () => (
  <div>
    <SEO 
        title="Web Hosting - Our completely scalable Web Hosting Plans" 
        description="Offer outstanding-quality web hosting solutions at reduced prices and we give you a 
        ton of charge-free tools, which will help you build your website in minutes."
    />  
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title">Our completely <span className="block text-indigo-600 xl:inline">scalable Web Hosting</span> Plans <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Offer outstanding-quality web hosting solutions at reduced prices and we give you a ton of charge-free tools, which will help you build your website in minutes.</p>
            </div>
            
            <Helmet>
            <link rel="stylesheet" href="https://comparetables.duoservers.com/plain.css?table_bkg=FFFFFF&table_color=000000&plan_names_bkg=EEEEEE&features_hover=F9F9F9&order_bkg=CCCCCC&order_color=000000"/>
            
            <script src="https://code.jquery.com/jquery-2.2.4.min.js" integrity="sha256-BbhdlvQf/xTY9gja0Dq3HiwQF8LaCRTXxZKRutelT44=" crossorigin="anonymous"></script>
            <script src="https://comparetables.duoservers.com/script.js?store_id=290832&service=hosting&style=plain&order_url=https://sheephostingbay.com/order-form/&order_text=Select Plan&container_id=compareTable&features_type=1"></script>

            </Helmet>
            <div id="compareTable" className="overflow-x-scroll shadow-lg"></div>
            
        </div>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
            <div className="rounded-md shadow">
                <a href="/website-hosting-comparison" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                Compare Plans
                </a>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
                <a href="https://sheephostingbay.duoservers.com/hosting-brochure/" rel="noreferrer" target="_blank" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                Download Brochure
                </a>
            </div>
        </div>
        
    </div>

    

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/hosting-control-panel-04.webp" className="w-60" alt="Web Hosting Control Panel" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">One–Stop Domain Control Solution </p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Manage all your web sites with a mouse click taking full advantage 
                                    of the custom point ’n’ click Web Control Panel. It is fitted out with a drag & drop File Manager, an all–in–one Domain Names 
                                    Manager for your domains (you can edit your WHOIS information and your NS records, lock, unclock and Whois Privacy Protect 
                                    your domains, create custom Domain Name System records, etcetera), a swift Mail Accounts Manager (you can forward email 
                                    messages, activate email spam protection, create auto–reply messages, create e–mail filters, activate anti–spoofing 
                                    protection, etcetera), a Databases Manager for managing numerous MySQL/PostgreSQL databases, a detailed statistics 
                                    user interface and many advanced tools.
                                    </div>
                                    <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" rel="noreferrer" target="_blank">
                                        <div
                                            className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                            <div>Explore more</div>
                                            <div className="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                    fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/hosting-backups-02.webp" className="w-60" alt="Data Backups" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Data Backups</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> No web site is shielded against cyber–thieves assaults. Your website might be 
                                    affected even by accidental website content deletion on your part. Still when using our backup service, we can easily recover all your 
                                    website content at any moment. Further than everyday server backups that we are creating, you can yourself make manual backups of your 
                                    web site content with one click through the File Manager, which is accessibly available in your Web Control Panel. Just create a 
                                    compressed archive of the file(s) you want to manually back up and put it in a location of your preference.
                                    </div>
                                    <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" rel="noreferrer" target="_blank">
                                        <div
                                            className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                            <div>Explore more</div>
                                            <div className="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                    fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/hosting-platform-02.webp" className="w-60" alt="Web Hosting Control Panel" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">True Cloud Platform</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> We have created our own custom–built website hosting platform based on the most recent 
                                    enterprise–grade technology. With each service (DNSs, e–mails, databases, web applications, etc.) handled by a separate server, your web site 
                                    content will operate in the cloud and will also be entirely protected against unexpected network outages and performance disturbances.
                                    </div>
                                    <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" rel="noreferrer" target="_blank">
                                        <div
                                            className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                            <div>Explore more</div>
                                            <div className="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                    fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>       

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/hosting-data-centers.webp" className="w-60" alt="Multiple Data Centers" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Multiple Data Centers</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Our website hosting network spreads across several continents – America (the 
                                    Steadfast datacenter facility in Chicago, IL, USA), Europe (the Pulsant datacenter facility in Maidenhead, UK, the Ficolo underground 
                                    data center in Pori, Finland and the Telepoint data center in Sofia, Bulgaria) and Australia (the Amaze datacenter in Sydney). 
                                    Because of this, no matter where you are actually located, you will be able to select a datacenter that is closest to your 
                                    target site visitors. Having done so, you’ll ensure the quickest feasible web site loading speeds for all of them. You 
                                    will be able to select your datacenter facility anytime with just a click on the signup page.
                                    </div>
                                    <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" rel="noreferrer" target="_blank">
                                        <div
                                            className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                            <div>Explore more</div>
                                            <div className="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                    fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/99-server-uptime-guarantee.webp" className="w-60" alt="99.9% Server Uptime Guarantee" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">99.9% Server Uptime Guarantee</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> Keeping your websites accessible online 24–7 is our top priority. We have developed our 
                                    very own website hosting platform where the server load is being divided evenly among a few web hosting servers to ensure that your sites will 
                                    remain online even if a specific machine is overloaded. Our 99.9 percent server uptime guarantee applies to all datacenters that we partner 
                                    with – in the USA, in Europe and in Australia.
                                    </div>
                                    <a href="https://sheephostingbay.duoservers.com/hosting-catalog/" rel="noreferrer" target="_blank">
                                        <div
                                            className="flex items-center mt-3 text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">
                                            <div>Explore more</div>
                                            <div className="ml-1 text-indigo-500"><svg viewBox="0 0 20 20"
                                                    fill="currentColor" className="w-4 h-4">
                                                    <path fillRule="evenodd"
                                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                                        clipRule="evenodd"></path>
                                                </svg></div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>      
                      
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-32">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 w-full overflow-hidden text-center">
                <h2>Choose your data center</h2>
                <p>No matter which data center you select, you’ll get a 99.9% network uptime guarantee with all cloud web hosting packages.</p>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="US Data Center" src="https://sheephostingbay.duoservers.com/template2/img/us-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">US Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Steadfast, Chicago, IL</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="UK Data Center" src="https://sheephostingbay.duoservers.com/template2/img/uk-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">UK Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Maidenhead, Berkshire, UK</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="AU Data Center" src="https://sheephostingbay.duoservers.com/template2/img/au-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">AU Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Amaze, Sydney, Australia</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="BG Data Center" src="https://sheephostingbay.duoservers.com/template2/img/bg-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">BG Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Telepoint, Sofia, Bulgaria</p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/5 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 bg-white shadow-lg rounded-lg mt-20 mb-5">
                    <div className="flex justify-center md:justify-end -mt-16">
                        <img className="w-20 h-20 object-cover rounded-full border-2 border-indigo-500" alt="Finland Data Center" src="https://sheephostingbay.duoservers.com/template2/img/fin-server-hosting-small.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Finland Data Center</h2>
                        <p className="text-gray-600 text-xs subpixel-antialiased">Ficolo/Pori, Finland</p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default HostingPage
